import * as React from "react";
// Global Layout

import Layout from "../components/layout/layout";

// Components
import Hero from "../components/hero/hero";
import FullWidthContainer from "../components/full-width-container/full-width-container";
import NestedContainer from "../components/nested-container/nested-container";
import Row6633 from "../components/row/row-66-33";
import RowEqual from "../components/row/row-equal";
import SectionHeader from "../components/section-header/section-header";
import { StaticImage } from "gatsby-plugin-image";
import TestimonialCallout from "../components/testimonial-callout/testimonial-callout";
import StatContainer from "../components/stat/stat-container";
import StatHeader from "../components/stat/stat-header";
import Stat from "../components/stat/stat";
import StatSource from "../components/stat/stat-source";
import VideoModal from "../components/video-modal/video-modal";

// Styles
import "./partners.css";

// Images
import playIcon from "../images/partners/Icon-Play-Video-Purple.svg";
import posterImageTenYears from "../images/partners/Video-Sharing-Heartfelt-Stories.jpg";
import testimonialImage1 from "../images/photos/Image-Dr-Fulton-Headshot.jpg";

const PartnersPage = () => {
  return (
    <Layout pageTitle="2021 BCBSRI Mission Report - Provider Partners">
      <div class="partners-page">
        <FullWidthContainer singleHero background={`var(--white-to-blue)`}>
          <Hero
            title="Provider Partners"
            intro="Providers stepped up when Rhode Islanders most needed their help through the pandemic, and we thank them for the services they provided then and continue to deliver now. We also appreciate the endless efforts of the diverse group of organizations and volunteers who work toward making quality healthcare accessible to all."
            reportType="partners"
            inner
            customers
            community
          >
            <StaticImage
              src="../images/partners/Image-Hero.jpg"
              alt="women talking"
            />
          </Hero>
        </FullWidthContainer>
        <div id="transforming-access-to-behavioral-healthcare">
          <FullWidthContainer background={`var(--blue-to-white)`}>
            <SectionHeader
              reportType={"provider partners"}
              title={"Transforming access to behavioral healthcare"}
              intro={
                "The uncertainty surrounding COVID-19 has increased the stress on our members and their families. It has highlighted the need for greater access to care, especially for mental health and substance use disorders. We are introducing new care models to do just that."
              }
            />
            <NestedContainer
              styleName=""
              background={`var(--white)`}
              hasShadow
              padded
            >
              <div className="partners-primary-care">
                <Row6633 doubleGap>
                  <StaticImage
                    className="partners-primary-care__image"
                    src="../images/partners/Image-Integration-with-Primary-Care.jpg"
                    alt="woman smiling"
                    width={780}
                  />
                  <div className="partners-primary-care__content">
                    <h3>Integration with primary care</h3>
                    <p>
                      Our members with behavioral health needs can benefit from
                      a pilot program we launched with the Women’s Medicine
                      Collaborative at Lifespan. In the pilot, primary care
                      providers (PCPs) receive support from BCBSRI to identify
                      and treat patients with depression, anxiety, and other
                      mental health conditions. Members can connect with a
                      behavioral health provider during their PCP visit and then
                      continue to receive care from their PCP, or they can
                      switch to a behavioral health provider for ongoing
                      treatment. The pilot also incorporates an alternative
                      payment model focused on quality and cost efficiency.
                    </p>
                  </div>
                </Row6633>
                <div style={{ marginTop: "3rem" }}>
                  <TestimonialCallout
                    quote="At the Women’s Medicine Collaborative, we believe that mental health is a key component to good health."
                    author="Margaret Miller"
                    role="M.D., FACP, Chief of Women’s Medicine for the Lifespan Physician Group and Medical Director of the Women’s Medicine Collaborative"
                  />
                </div>
              </div>
            </NestedContainer>
            <NestedContainer padded paddedLast>
              <div className="partners-care-management">
                <Row6633 doubleGap>
                  <div>
                    <RowEqual>
                      <div className="partners-care-management__content">
                        <h3>Care management online</h3>
                        <p>
                          We all like to see the person we are talking to and
                          getting help from. Our new virtual care management
                          program meets members where they are and offers the
                          reassurance of a face-to-face conversation. BCBSRI
                          nurses can visit virtually while the member is in
                          their own home, in addition to keeping in touch
                          through telephone calls. Seeing members also gives
                          nurses the ability to better identify health issues
                          that weren’t previously addressed.
                        </p>
                        <div className="podcast-cta">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 29"
                          >
                            <path
                              d="M9 23.951V27H7a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2h-2v-3.049A10 10 0 0 0 20 14v-1a1 1 0 0 0-2 0v1a8 8 0 0 1-16 0v-1a1 1 0 0 0-2 0v1a10 10 0 0 0 9 9.951zM4.34 4c-.115.325-.201.66-.258 1H6.5a.5.5 0 0 1 0 1H4v1h2.5a.5.5 0 0 1 0 1H4v6a6 6 0 1 0 12 0V8h-2.5a.5.5 0 0 1 0-1H16V6h-2.5a.5.5 0 0 1 0-1h2.417a5.949 5.949 0 0 0-.259-1H13.5a.5.5 0 0 1 0-1h1.7A6 6 0 0 0 4.8 3h1.7a.5.5 0 0 1 0 1H4.34z"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              fill="#A20067"
                            />
                          </svg>
                          <span className="podcast-cta__text">
                            “It’s always nice to have a nurse in your back
                            pocket”
                          </span>
                          <a
                            href="https://soundcloud.com/therhodetohealth/its-always-nice-to-have-a-nurse-in-your-back-pocket-s4-ep10?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                            className="btn btn--burgandy"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>listen to podcast</span>
                          </a>
                        </div>
                      </div>
                      <div className="partners-care-management__image">
                        <StaticImage
                          src="../images/partners/Illustration-Care-Management-Online.png"
                          alt=""
                          width={360}
                        />
                      </div>
                    </RowEqual>
                  </div>
                  <StatContainer>
                    <StatHeader title="How It Helped" />
                    <Stat
                      stat={"20%"}
                      description={"received a community-based resource"}
                    />
                    <Stat
                      stat={"25%"}
                      description={"learned the signs of their condition"}
                    />
                    <Stat stat={"50%"} description={"avoided an ER visit"} />
                    <Stat stat={"100%"} description={"satisfaction rate"} />
                    <StatSource
                      source={
                        "Among BCBSRI members who have participated in our care management program"
                      }
                    />
                  </StatContainer>
                </Row6633>
              </div>

              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  quote="This is a game-changer for the nurse-patient relationship."
                  author="Smita Mazumdar"
                  role="RN, BSN, CCM, Director of Care Management, BCBSRI"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
        <div id="listening-to-providers">
          <FullWidthContainer styleName="" background={`var(--color-17)`}>
            <SectionHeader
              reportType={"provider partners"}
              title={"Listening to providers"}
            />
            <NestedContainer padded>
              <div className="partners-listening">
                <Row6633 doubleGap>
                  <StaticImage
                    className="partners-listening__image"
                    src="../images/partners/Illustration-Listening-to-Providers.jpg"
                    alt=""
                    width={780}
                  />
                  <div className="partners-listening__content">
                    <p>
                      While any hospital stay can be challenging, an unexpected
                      readmission—especially one that might have been
                      avoided—can be particularly unsettling for patients and
                      their families. We have been collaborating with providers
                      to reduce hospital readmissions after a patient has been
                      sent home. By addressing the root causes of unplanned
                      hospital readmissions and emergency room visits, providers
                      are helping to reduce healthcare costs while also ensuring
                      quality care.
                    </p>
                    <p>
                      Two proposed programs are already up and running and
                      working to meet the needs of BCBSRI members.
                    </p>
                  </div>
                </Row6633>
              </div>
            </NestedContainer>

            <NestedContainer
              styleName=""
              className="safer-homes"
              border={`thick-white`}
              background={`var(--color-19)`}
              hasShadow
            >
              <div className="partners-process-testimonial">
                <Row6633 doubleGap>
                  <div className="partners-process-testimonial__content">
                    <h3>
                      Coastal Medical – Reducing Readmissions by Transforming
                      Patient Access
                    </h3>
                    <p>
                      Coastal Medical developed a remote monitoring program that
                      helps patients stay connected to their care teams
                      following discharge. Now, healthcare providers can more
                      easily connect with patients when needed, and care
                      managers can be alerted and respond to a situation before
                      a patient has to go to an emergency room or back into the
                      hospital.
                    </p>

                    <figure className="testimonial">
                      <div className="testimonial__content-wrap">
                        <div className="testimonial__image">
                          <StaticImage
                            src="../images/photos/Image-Dr-McGookin-Headshot.jpg"
                            alt="Dr. Edward McGookin"
                          />
                        </div>
                        <div className="testimonial__content">
                          <span className="testimonial__border"></span>
                          <blockquote>
                            “Remote Patient Monitoring has been a game-changer
                            for Coastal patients. In a time when isolation is so
                            prevalent, RPM allows us to stay closely connected
                            to our patients, meet them where they are in their
                            health journey, and deliver the timely care they
                            need how they want it.”
                          </blockquote>
                          <figcaption>
                            <cite>&mdash;&nbsp;Dr. Edward McGookin</cite>, Chief
                            Medical Officer, Coastal Medical
                          </figcaption>
                        </div>
                      </div>
                    </figure>
                  </div>
                  <div>
                    <StaticImage
                      className="partners-process-testimonial__image"
                      src="../images/partners/Image-Coastal-Medical.jpg"
                      alt=""
                    />
                  </div>
                </Row6633>
              </div>
            </NestedContainer>

            <NestedContainer padded paddedLast>
              <div className="partners-program-highlight">
                <Row6633 doubleGap>
                  <div className="partners-program-highlight__content">
                    <h3>Integra – Integra at Home Community Paramedicine</h3>
                    <StaticImage
                      src="../images/partners/Image-Integra-at-Home.jpg"
                      alt=""
                      width={780}
                      className="partners-program-highlight__image"
                    />
                    <p className="pt-sm">
                      This program provides 24/7 on-demand, at-home care for
                      patients at high risk. A care provider will be dispatched
                      to a patient’s home within 30 minutes, and they can
                      provide many health services, such as an EKG, bloodwork,
                      home safety evaluation, or medication check.
                    </p>
                    <a
                      className="partners-program-highlight__link"
                      href="https://www.bcbsri.com/employers/change/leadership/helping-members-stay-home-new-ideas-reduce-readmissions"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Read how BCBSRI is helping members stay home
                    </a>
                  </div>
                  <StatContainer>
                    <StatHeader title="An initial pilot of this program has already produced impressive results:" />
                    <Stat stat={"27%"} description={"reduction in ER visits"} />
                    <Stat
                      stat={"20%"}
                      description={"reduction in inpatient admissions"}
                    />
                    <Stat
                      stat={"15%"}
                      description={"reduction in hospital readmissions"}
                    />
                  </StatContainer>
                </Row6633>
              </div>
              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  image={testimonialImage1}
                  quote="The Integra at Home program has allowed us to provide care that truly honors what matters most to our patients. We can respond 7 days a week, 24 hours a day so our patients can be surrounded by family and the comforts of home."
                  author="Dr. Ana Tuya Fulton"
                  role="Chief Medical Officer, Integra Community Care Network"
                />
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div id="expanding-health-equity-for-more-rhode-islanders">
          <FullWidthContainer styleName="">
            <SectionHeader
              reportType={"provider partners"}
              title={"Expanding health equity for more Rhode Islanders"}
              intro={
                "Our vision extends beyond our organization, so we gladly support those who work to create a state of health and well-being across the Ocean State."
              }
            />
            <NestedContainer>
              <div className="partners-patients-callout">
                <Row6633 reverse>
                  <StatContainer>
                    <StatHeader title="Patients" />
                    <Stat
                      stat={"13,938"}
                      description={
                        "patients served in 2021 by Clínica Esperanza/ Hope Clinic"
                      }
                    />
                  </StatContainer>
                  <div className="partners-patients-callout__content">
                    <h3 style={{ color: "#fff" }}>
                      Neighborhood health station
                    </h3>
                    <p style={{ color: "#B6BDC0" }}>
                      More Rhode Islanders in the Olneyville and Valley
                      neighborhoods now have access to healthcare. Support from
                      BCBSRI helped Clínica Esperanza/Hope Clinic maintain a
                      neighborhood health station. With simple health checks,
                      residents can learn about chronic conditions and, if
                      necessary, be enrolled into preventive health services.
                    </p>
                  </div>
                </Row6633>
              </div>
            </NestedContainer>

            <NestedContainer padded styleName="">
              <SectionHeader
                small
                title={"Free healthcare services"}
                intro={
                  "In 2021, more than 4,400 low-income, uninsured RI adults benefited from more than 14,000 visits, including medical, dental, behavioral health, and COVID-19 response. These were provided in person and virtually by the Rhode Island Free Clinic, which honored BCBSRI with the 2021 Founders’ Award. As one of the organization’s major partners, we helped fund their 2021 services."
                }
              />
              <div className="partners-free-healthcare">
                <Row6633 doubleGap>
                  <div className="partners-free-healthcare__content">
                    <StaticImage
                      src="../images/partners/Illustration-Free-Healthcare-Services.jpg"
                      alt=""
                      width={780}
                      className="partners-free-healthcare__image"
                    />
                  </div>
                  <StatContainer>
                    <StatHeader title="2021 Rhode Island Free Clinic services" />
                    <Stat stat={"2,900"} description={"primary care visits"} />
                    <Stat
                      stat={"4,800"}
                      description={"specialty care visits"}
                    />
                    <Stat stat={"18,400"} description={"free prescriptions"} />
                    <Stat
                      stat={"5,700"}
                      description={
                        "COVID-19 tests and vaccines, including follow-up care for all who tested positive"
                      }
                    />
                  </StatContainer>
                </Row6633>
              </div>
              <div style={{ marginTop: "3rem" }}>
                <TestimonialCallout
                  quote="One of the most important things about the survival of a free clinic is to have strong partners. We couldn’t have done what we’ve done without Blue Cross’ support."
                  author="Marie Ghazal"
                  role="Chief Executive Officer, Rhode Island Free Clinic"
                />
              </div>
            </NestedContainer>

            <NestedContainer padded paddedLast styleName="">
              <Row6633 divider>
                <div className="partners-honored-partners">
                  <div className="partners-honored-partners__content">
                    <StaticImage
                      className="partners-honored-partners__image"
                      src="../images/partners/Logo-RI-Foundation.svg"
                      alt=""
                      width={220}
                    />
                    <h3>Honored to be a partner</h3>
                    <p>
                      We are proud to have received the 2021 Carter Inspiring
                      Partner Award from the Rhode Island Foundation. This award
                      recognizes donors that have demonstrated a thoughtful
                      approach and commitment to philanthropy.
                    </p>
                    <figure className="testimonial">
                      <span className="testimonial__border"></span>
                      <blockquote>
                        “Blue Cross has been making critical investments for
                        decades aimed at increasing access to affordable
                        healthcare and improving health equity, a mission and
                        vision closely aligned with our priorities.”
                      </blockquote>
                      <figcaption>
                        <cite>&mdash;&nbsp;Neil D. Steinberg</cite>, President &
                        CEO, Rhode Island Foundation
                      </figcaption>
                    </figure>
                    <a
                      className="partners-honored-partners__link"
                      href="https://vimeo.com/604919061"
                      target="_blank"
                      rel="noreferrer"
                    >
                      See our partners talk about our collaborations
                    </a>
                  </div>
                </div>
                <div className="partners-loan-options">
                  <StaticImage
                    className="partners-loan-options__icon"
                    src={"../images/partners/Icon-Repayment.svg"}
                    alt=""
                    width={60}
                  />
                  <div className="partners-loan-options__content">
                    <h3>Loan repayment options</h3>
                    <p>
                      BCBSRI funding makes it possible for the Rhode Island
                      Health Professionals Loan Repayment Program to improve
                      access to care and retain providers who deliver healthcare
                      services to Rhode Islanders who need it most. The program
                      addresses health professional shortages that lead to
                      disparities in health. In 2021 education loan repayment
                      options benefited 37 licensed health professionals who
                      work in medically underserved communities.
                    </p>
                  </div>
                </div>
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div>
          <span id="sharing-heartfelt-stories" class="anchor"></span>
          <FullWidthContainer
            single
            styleName="partners-heartfelt-stories"
            background={`var(--color-2)`}
          >
            <SectionHeader
              small
              title={"Sharing heartfelt stories"}
              intro={
                "Watch these women share their stories about surviving heart disease and stroke. BCBSRI and the Southern New England American Heart Association (AHA) created the Heart2Heart Storytellers campaign in 2017. We donated $25,000 to the AHA on behalf of the 2021 Storytellers to help raise awareness about these leading causes of death in women."
              }
            />
            <NestedContainer
              padded
              className={"partners-heartfelt-stories-nested"}
            >
              <Row6633 reverse>
                <figure className="testimonial">
                  <span
                    className="testimonial__border"
                    style={{ borderTop: "1px solid rgba(255, 255, 255, .20)" }}
                  ></span>
                  <blockquote style={{ color: "#B6BDC0" }}>
                    It’s really important to share your story. Because if we
                    don’t share our stories, how are other women going to know
                    it could happen to them? And how are they going to know what
                    to do if they start having shortness of breath?
                  </blockquote>
                  <figcaption style={{ color: "#B6BDC0" }}>
                    <cite>&mdash;&nbsp;Kim Dolan</cite>, Heart2Heart Storyteller
                  </figcaption>
                </figure>
                <VideoModal
                  ariaHideApp={false}
                  posterImage={posterImageTenYears}
                  videoPlayIcon={playIcon}
                  videoSource="https://player.vimeo.com/video/690669987?h=5bd28e8b1f"
                />
              </Row6633>
            </NestedContainer>
          </FullWidthContainer>
        </div>

        <div id="increasing-care-for-the-lgbtq-community">
          <FullWidthContainer styleName="" background={`var(--blue-to-white)`}>
            <NestedContainer>
              <SectionHeader
                reportType={"provider partners"}
                title={"Increasing care for the LGBTQ community"}
                intro={
                  "Our LGBTQ members and their families face significant challenges in their lives, and the doctor’s office shouldn’t present another hurdle."
                }
              />
            </NestedContainer>

            <NestedContainer
              padded
              styleName=""
              background={`var(--white)`}
              hasShadow
            >
              <div className="partners-community-care">
                <Row6633 doubleGap>
                  <StaticImage
                    className="partners-community-care__image"
                    src="../images/partners/Image-Increasing-Care-for-the-LGBTQ-Community.jpg"
                    alt=""
                    width={780}
                  />
                  <div className="partners-community-care__content">
                    <StaticImage
                      className="partners-community-care__icon"
                      src="../images/partners/Logo-Safe-Zone.png"
                      alt=""
                      width={90}
                    />
                    <p>
                      More providers are offering safe, affirming, and inclusive
                      care to the LGBTQ community with help from the BCBSRI Safe
                      Zone certification program. Six organizations, with a
                      dozen locations, were certified in 2021, joining nearly
                      three dozen other locations statewide.
                    </p>
                    <p>
                      The facilities include primary care, behavioral health,
                      child and family services, and organizations serving
                      individuals who have experienced sexual assault, domestic
                      abuse and substance abuse as well as the first pediatric
                      practice: Coastal Medical Waterman Pediatrics.
                    </p>
                    <a
                      className="partners-community-care__link"
                      href="https://www.bcbsri.com/safezones"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Find a certified Safe Zone provider
                    </a>
                  </div>
                </Row6633>
                <div style={{ marginTop: "3rem" }}>
                  <TestimonialCallout
                    quote="We want kids and teens to know that when they see the Safe Zone logo on the door at Waterman Pediatrics, they will receive compassionate, affirming care and that we have resources at the ready for both patients and their families."
                    author="Elizabeth Lange"
                    role="MD, FAAP, Coastal Medical Waterman Pediatrics"
                  />
                </div>
              </div>
            </NestedContainer>
            <NestedContainer padded paddedLast>
              <div
                id="engaging-diverse-suppliers"
                className="partners-diverse-suppliers"
              >
                <Row6633 reverse>
                  <StaticImage
                    src="../images/partners/Image-Engaging-Diverse-Suppliers.jpg"
                    alt=""
                    className="partners-diverse-suppliers__image"
                    width={360}
                  />
                  <div className="partners-diverse-suppliers__content">
                    <h3>Engaging diverse suppliers</h3>
                    <p>
                      We understand the important role that our suppliers play
                      in our ability to deliver innovative solutions to our
                      customers in an increasingly dynamic healthcare ecosystem.
                      With that in mind, we revamped our supplier diversity
                      program, actively encouraging qualified minority- and
                      women-owned suppliers from all segments of the business
                      community to participate in our corporate procurement
                      process.
                    </p>
                    <ul>
                      <li>Minority-owned businesses</li>
                      <li>Woman-owned businesses</li>
                      <li>Veteran-owned businesses</li>
                      <li>LGBTQ-owned businesses</li>
                      <li>
                        Disadvantaged business enterprises and small
                        disadvantaged businesses
                      </li>
                      <li>Disability-owned businesses</li>
                    </ul>

                    <a
                      className="partners-diverse-suppliers__link"
                      href="https://www.bcbsri.com/about/diversity/supplier-diversity-program"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Participate in our supplier diversity program
                    </a>
                  </div>
                </Row6633>
              </div>
            </NestedContainer>
          </FullWidthContainer>
        </div>
      </div>
    </Layout>
  );
};

export default PartnersPage;
